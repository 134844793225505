import { NavigateNext } from '@mui/icons-material';
import {
    Breadcrumbs as MuiBreadcrumbs, Typography, Link, styled, Tooltip,
} from '@mui/material';
import {
    Link as RouterLink, useLocation,
} from 'react-router-dom';

const StyledMuiBreadcrumbs = styled(MuiBreadcrumbs)(({ theme }) => ({
    padding: theme.spacing(2, 0),
}));

// Text to display in place of the key text
const breadcrumbNameMap: Record<string, string> = {
    matchervalidations: 'Matcher Validations',
    ingestjobs: 'Ingest Jobs',
    visualizations: 'Visualizations',
    consolidation: 'Consolidation Configuration',
};

// Text to display when the previous path name is the key (avoids showing guid's)
const breadcrumbNameBasedOnPreviousMap: Record<string, string> = {
    association: 'Edit Configuration',
    ingestjobs: 'Job Details',
};

// Breadcrumbs to purposely not show (lead to dead links)
const hiddenBreadCrumbs = ['association', 'configuration', 'product'];

export const Breadcrumbs = (): JSX.Element | null => {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);

    const getBreadcrumbText = (value: string, index: number): string => {
        if (value in breadcrumbNameMap) return breadcrumbNameMap[value];

        if (index > 0 && pathnames[index - 1] in breadcrumbNameBasedOnPreviousMap) {
            return breadcrumbNameBasedOnPreviousMap[pathnames[index - 1]];
        }

        return value;
    };

    return pathnames.length ? (
        <StyledMuiBreadcrumbs separator={<NavigateNext fontSize="small" />}>
            <Tooltip title="Go to product search page">
                <Link color="inherit" component={RouterLink as any} to="/" underline="hover">
                    Search
                </Link>
            </Tooltip>
            {pathnames.map((value, index) => {
                // Don't show hidden breadcrumbs
                if (hiddenBreadCrumbs.includes(value)) {
                    return null;
                }
                const last = index === pathnames.length - 1;
                const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                const breadcrumbText = (getBreadcrumbText(value, index));

                return (last ? (
                    <Typography color="textPrimary" key={to}>
                        {breadcrumbText}
                    </Typography>
                ) : (
                    <Link color="inherit" component={RouterLink as any} key={to} to={to} underline="hover">
                        {breadcrumbText}
                    </Link>
                ));
            })}
        </StyledMuiBreadcrumbs>
    ) : null;
};
