export const CREATE = 'create';
export const LOGOUT = '/logout';
export const ROOT = '/';
export const PRODUCT_DETAILS = 'product/:productKey';
export const PRODUCT_DETAILS_ASSOCIATIONS = '';
export const PRODUCT_DETAILS_JOBS = 'ingestjobs';
export const PRODUCT_MATCHER_VALIDATIONS = 'matchervalidations';
export const PRODUCT_VISUALIZATIONS = 'visualizations';
export const PRODUCT_CONSOLIDATION = 'consolidation';
export const FULL_PRODUCT_VISUALIZATIONS = `${PRODUCT_MATCHER_VALIDATIONS}/${PRODUCT_VISUALIZATIONS}`;
export const PRODUCT_ASSOCIATION_CONFIG = '/product/:productKey/association/:associationId/configuration';
export const CREATE_PRODUCT_ASSOCIATION = 'product/:productKey/association/create';
export const INGEST_JOB_DETAILS = '/product/:productKey/ingestjobs/:ingestJobId';
export const VISUALIZATIONS_NAV_DETAILS = '/product/:productKey/visualizations';
